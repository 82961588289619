{
  "name": "noteradar",
  "version": "1.2.6",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o",
    "build-dev": "ng build",
    "build-prod": "ng build --configuration=production",
    "copy-dev": "node transfer.js dev",
    "copy-prod": "node transfer.js prod"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.3",
    "@angular/cdk": "^17.3.3",
    "@angular/common": "^17.3.3",
    "@angular/compiler": "^17.3.3",
    "@angular/core": "^17.3.3",
    "@angular/forms": "^17.3.3",
    "@angular/platform-browser": "^17.3.3",
    "@angular/platform-browser-dynamic": "^17.3.3",
    "@angular/router": "^17.3.3",
    "@ckeditor/ckeditor5-angular": "^7.0.1",
    "@ckeditor/ckeditor5-build-classic": "^41.2.1",
    "@coreui/angular": "^2.14.0",
    "@coreui/coreui": "^2.1.16",
    "@coreui/coreui-pro": "^2.1.16",
    "@coreui/icons": "1.0.1",
    "@fortawesome/fontawesome-free": "^6.5.2",
    "@googlemaps/markerclustererplus": "^1.2.10",
    "@ngx-matomo/router": "^4.1.2",
    "@ngx-matomo/tracker": "^4.1.2",
    "@ngx-translate/core": "^15.0.0",
    "angular-calendar": "^0.31.0",
    "bootstrap": "^4.6.2",
    "chart.js": "^4.4.2",
    "cookieconsent": "^3.1.1",
    "crypto-es": "^2.1.0",
    "date-fns": "^3.6.0",
    "dayjs": "^1.11.10",
    "html2canvas": "^1.4.1",
    "jquery": "^3.7.1",
    "jquery-ui": "^1.12.1",
    "jwt-decode": "^4.0.0",
    "ng2-charts": "^5.0.4",
    "ng2-file-upload": "^5.0.0",
    "ngx-bootstrap": "^12.0.0",
    "ngx-captcha": "^13.0.0",
    "ngx-cookieconsent": "^6.0.0",
    "ngx-pagination": "^6.0.3",
    "ngx-scrollbar": "^14.0.0",
    "ngx-toastr": "^18.0.0",
    "ngx-translate-multi-http-loader": "^17.0.0",
    "rxjs": "^7.8.1",
    "stacktrace-js": "^2.0.2",
    "sweetalert2": "^11.9.0",
    "tslib": "^2.5.2",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.3",
    "@angular/cli": "^17.3.3",
    "@angular/compiler-cli": "^17.3.3",
    "@types/google.maps": "^3.54.7",
    "@types/node": "^18.16.14",
    "node-ssh": "^13.1.0",
    "typescript": "~5.4.4",
    "zip-a-folder": "^3.1.3"
  }
}
