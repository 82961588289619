import {FwSettings} from './services/settings.service';

export const standaloneConfig: FwSettings = {
    appID: 'noteradar',
    urlDataFallbackLink: '/app/dashboard',
    cryptoKey: 'FbcCY2yCFBwVCUE9R+6kJ4fAL4BJxxjd',
    auth: {
        loginDestination: '/login',
        afterLoginDestination: '/app/dashboard',
        afterLogoutDestination: '',
        googleRecaptcha: false,
        googleRecaptchaSiteKey: '',
        interceptorWhitelist: [],
    },
    file: {
        fileViewerFileInfo: {
            rows: [
                {
                    valueTrans: 'FW.FILE.NAME',
                    field: 'display_name',
                    editable: true,
                    required: true,
                },
                {
                    valueTrans: 'FW.FILE.DESCRIPTION',
                    field: 'description',
                    editable: true,
                    required: false,
                },
                {
                    valueTrans: 'FW.FILE.SIZE',
                    suffix: '(KB)',
                    field: 'size'
                },
                {
                    valueTrans: 'FW.FILE.ADD_BY',
                    field: 'creator_name'
                },
                {
                    valueTrans: 'FW.FILE.ADD_DATE',
                    field: 'create_date'
                }
            ]
        }
    }
};
