import {Injectable} from '@angular/core';
import {UserService} from '../../framework/modules/auth/user.service';
import {DataService} from '../../framework/services/data.service';

@Injectable({
    providedIn: 'root'
})
export class AccessService {

    rights: any = {};

    constructor(
        private userService: UserService,
    ) {
    }

    currentUID(): string {
        return this.userService.currentUser.UID;
    }

    isOwner(UID: string): boolean {
        return UID === this.currentUID();
    }

    /**
     * Since the admin role always exists we can use this
     */
    isAdmin(): boolean {
        return this.userService.currentUser.id_role === '1';
    }

    hasRight(right: string): boolean {
        return this.userService.currentUser.rights.includes(right);
    }
}
