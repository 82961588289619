<div class="modal fade" id="remove-localstorage-modal" tabindex="-1" role="dialog" bsModal [config]="{backdrop: 'static'}" #staticModal="bs-modal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Remove localStorage items</h5>
                <button type="button" class="close" (click)="staticModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <span *ngIf="keys.length == 0">No items found!</span>
                <ng-container *ngIf="keys.length > 0">
                    <button type="button" class="btn btn-secondary btn-sm" (click)="selectAll()">Select all</button>
                    <button type="button" class="btn btn-secondary btn-sm ml-2" (click)="deselectAll()">Select none</button>
                    <form class="mt-2">
                        <div class="form-check" *ngFor="let key of keys">
                            <input type="checkbox" [name]="key.name" class="form-check-input" [(ngModel)]="key.checked">
                            <label class="form-check-label">{{ key.name }}</label>
                            <i class="fa fa-info-circle ms-2" (click)="showInfo(key)"></i>
                            <pre *ngIf="key.show" class="p-1">{{ key.data | json }}</pre>
                        </div>
                    </form>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="staticModal.hide()">Close</button>
                <button type="button" class="btn btn-danger" *ngIf="keys.length > 0" data-dismiss="modal" (click)="remove()">Delete</button>
            </div>
        </div>
    </div>
</div>
