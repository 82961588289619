import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {standaloneConfig} from '../../settings';

@Component({
    selector: 'app-localstorage-info',
    templateUrl: './localstorage-info.component.html',
    styleUrls: ['./localstorage-info.component.scss']
})
export class LocalstorageInfoComponent {

    @ViewChild('staticModal') modalDiv: any;
    // The key that will show the info modal
    @Input() key = 'q';

    keys: any[] = [];

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent): void {
        event.preventDefault();
        if (event.ctrlKey && event.key === this.key) {
            this.getKeys();
            this.modalDiv.show();
        }
    }

    constructor(
        private toastrService: ToastrService
    ) {
    }

    getKeys(): void {
        // get all localStorage keys
        this.keys = Object.keys(localStorage);

        // filter only those which belong to the app according to the settings
        this.keys = this.keys.filter(key => {
            return key.startsWith(standaloneConfig.appID);
        }).map(key => {
            return {name: key, checked: false, show: false};
        });
    }

    remove(): void {
        this.keys.forEach(key => {
            if (key.checked) {
                localStorage.removeItem(key.name);
            }
        });
        this.getKeys();
        this.toastrService.info('Items were deleted!');
    }

    showInfo(key: any): void {
        key.show = !key.show;
        const info = localStorage.getItem(key.name);
        if (info) {
            key.data = JSON.parse(info);
        }
    }

    selectAll(): void {
        this.keys.forEach(key => {
            key.checked = true;
        });
    }

    deselectAll(): void {
        this.keys.forEach(key => {
            key.checked = false;
        });
    }
}
