<form [formGroup]="resetForm" class="form" *ngIf="hashValid">

    <div class="form-group">
        <label>{{'FW.USER.PASSWORD' | translate}}</label>
        <input class="form-control" type="password" formControlName="password" minlength="6" autocomplete="off">
        <small class="text-danger" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors?.required">Eingabe erforderlich</small>
        <small class="text-danger" *ngIf="resetForm.controls.password.errors?.minlength">{{ 'FW.USER.PW_TO_SHORT' | translate}}</small>
        <div class="pt-1">
            <label>{{ 'FW.USER.PASSWORD_REPEAT' | translate }}</label>
            <input class="form-control" type="password" formControlName="confirmPassword" autocomplete="off" required>
            <small class="text-danger" *ngIf="resetForm.controls.confirmPassword.errors?.nomatch">{{ 'FW.USER.PW_NOT_MATCH' | translate}}</small>
        </div>
    </div>

    <div class="text-center">
        <button class="btn btn-dark mr-2 pb-2" type="submit" [disabled]="resetForm.invalid" (click)="reset()">
            <i class="fas fa-check"></i> {{'FW.PASSWORD_RESET.RESET' | translate }}
        </button>
    </div>

</form>

<div class="text-center mt-3" *ngIf="!hashValid">
    <p>{{'FW.PASSWORD_RESET.MSG_TIMEOUT' | translate}}</p>
    <button type="button" class="btn btn-dark" [routerLink]="[settingsService.frameworkSettings.auth.loginDestination]">Zum Login</button>
</div>
