import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as StackTrace from 'stacktrace-js';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private http: HttpClient,
        private toastrService: ToastrService,
        private translateService: TranslateService,
    ) {
    }

    request(action: string, dataObject: object = null): Observable<any> {
        const stackFrames = StackTrace.getSync();

        let componentName = null;
        let methodName = null;

        if (stackFrames[1].functionName) {
            const parts = stackFrames[1].functionName.split('.');
            componentName = parts[parts.length - 2] + '.ts';
            methodName = parts[parts.length - 1] + '()';
        }

        return this.http
            .post('api/api.php', {
                action,
                data: dataObject,
                componentName,
                methodName
            })
            .pipe(
                tap((response: any) => {
                    if (response?.errors) {
                        response.errors.forEach((error: any) => {
                            this.toastrService.error(
                                error === 'FW.ERRORS.DB' ? this.translateService.instant('FW.ERRORS.DB') : error,
                                this.translateService.instant('FW.ERRORS.TITLE')
                            );
                        });
                    }

                    if (response?.warnings) {
                        response.warnings.forEach((error: any) => {
                            this.toastrService.warning(error);
                        });
                    }
                })
            );
    }
}




