import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthInfoComponent} from './components/auth-info/auth-info.component';
import {LocalstorageInfoComponent} from './components/localstorage-info/localstorage-info.component';
import {AuthResetPasswordComponent} from './modules/auth/auth-reset-password/auth-reset-password.component';
import {MaintenanceInfoComponent} from './components/maintenance-info/maintenance-info.component';
import {DebounceKeyupDirective} from './directives/debounce-keyup.directive';
import {YesNoPipe} from './pipes/yes-no.pipe';
import {FwTranslatePipe} from './pipes/fw-translate.pipe';
import {ModalModule} from 'ngx-bootstrap/modal';

@NgModule({
    declarations: [
        LocalstorageInfoComponent,
        AuthInfoComponent,
        AuthResetPasswordComponent,
        MaintenanceInfoComponent,
        YesNoPipe,
        DebounceKeyupDirective,
        FwTranslatePipe,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ModalModule,
    ],
    exports: [
        LocalstorageInfoComponent,
        AuthInfoComponent,
        AuthResetPasswordComponent,
        MaintenanceInfoComponent,
        DebounceKeyupDirective,
        FwTranslatePipe,
        YesNoPipe
    ]
})
export class FrameworkModule {
}
